<template>
  <v-container>
      <div>
    <div
      v-for="(selected, index) in selections"
      v-bind:key="index"
      style="white-space: pre-line"
    >
      {{ selected.message }}<span class="btn-remove" @click="removeLine(index)">x</span>
    </div>
    <v-btn class="btn-done" elevation-24 @click="submit">Done</v-btn>
      </div>
  </v-container>
</template>
<script>
export default {
  name: "Selection",
  props: ["selections"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    removeLine(index) {
      this.selections.splice(index, 1);
    },
    // remove everything
    submit() {
      this.$emit('submit', this.selections)
      this.selections.splice(0, this.selections.length)
    }
  },
};
</script>
<style scoped>
.btn-remove {
    color: transparent;
}
.btn-remove:hover {
    cursor: pointer;
    color: red;
}
span::selection {
    color: none;
    background: none;
}
.btn-done {
    margin-top: 10%;
}
</style>