<template>
    <v-container>
        <v-row>
            <v-col>
        <body>
            <article>
                <section>
                    <p class="main-text">
                        <span 
                            v-for="(message, index) in messages" 
                            v-bind:key="index"
                            @click.stop="doSomething($event, index)"
                            class="word censored">&nbsp;{{message}}&nbsp;</span>
                    </p>
                </section>
            </article>
        </body>
        <v-btn elevation-2 color="crimson" @click="getUncovered">Grab Line</v-btn> 
            </v-col>
            <v-col v-if="selection.length > 0">
                <div >
                    <Selection @submit="submitSelected" v-bind:selections="selection"/>
                </div>
            </v-col>
        </v-row>
                        <div id="afterword">
                    <a href="https://tilde.town/~kc/blackout/">Ispiration</a>
                </div>
    </v-container>
</template>

<script>

import Selection from './Selection';
import apiClient from '../apiClient';
export default {
    name: 'Censor',
    components: {
        Selection,
    },
    data() {
        return {
            selection: [],
            orig: undefined,
            messages: [],
            selectionReals: [],
        }
    },
    methods: {
        doSomething(evt, idx) {
            let censored = evt.target.className;
            censored = censored === "word censored" ? "word up" : "word censored" 
            this.selectionReals[idx] = censored === "word up" ? { message: this.messages[idx], index: idx } : ""
            console.log(this.selectionReals[idx])
            evt.target.className = censored
        },
        getUncovered() {
            const selected = Array.from(document.getElementsByClassName("word up"))
            if(selected && selected.length > 0 ) {
                // filter selected reals into array of ints
                this.selectionReals = this.selectionReals.filter(function (el) { 
                    return el; 
                });

                var sentence = this.selectionReals.map(x => {
                    return x.message
                }).join(' ');
                var idx = this.selectionReals.map(x => {
                    return x.index
                }).join(',');

                this.selection.push({ message: sentence, index: idx })
                this.selectionReals.length = 0 
                selected.forEach(element => {
                    element.className = "word censored" 
                });
            }
        },
        submitSelected(selection) {
            const sub = {
                OriginalID: this.orig.id,
                Indices: selection.map(x => x.index)
            }
            apiClient.post("/newCreation", sub)
            .then(resp => {
                if(resp) {
                this.selection.splice(0, this.selection.length-1)
                }
            })
        }
    },
    mounted()  {
        apiClient
            .get("/currentCensor")
            .then(response => {
                this.orig = response.data
                this.messages.push(...response.data.text) 
                })
    }
}
</script>

<style scoped>
    body {
        font-family: monospace;
        font-size: 28px;
        background-color: white;
        color: black;
    }

    article {
        line-height: 1.4em;
        max-width: 30em;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3em;
        text-align: left;
    }

    .main-text {
        font-family: "keith", Georgia, serif;
    }

    section div {
        font-family: "keith", Georgia, serif;
        text-align: right;
        font-size: 0.8em;
    }

    .word {
        cursor: pointer;
        display: inline-block;
    }

    .word.censored {
        background-color: black;
    }

    .word.censored::selection {
        background-color: crimson;
    }

    .word:hover {
        background-color:cornflowerblue;
    }

    .word.censored:hover {
        background-color: beige;
    }

    #afterword {
        font-family: Georgia, serif;
        font-size: 0.6em;
        color: #444;
        text-align: center;
        margin: 3em;
    }
</style>