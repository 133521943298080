<template>
  <v-app>
    <v-main>
      <Censor/>
    </v-main>
  </v-app>
</template>

<script>
import Censor from './components/Censor';

export default {
  name: 'App',

  components: {
    Censor,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
    font-family: "keith";
    src: url("fonts/Keith-Regular.ttf");
}
#app {
  font-family: "keith", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
